export let countriesPhoneCodes=  [
    {
        "iso2": "US",
        "iso3": "USA",
        "name": "United States",
        "phone_code": "+1",
        "emoji": "🇺🇸",
        "phone_format_with_iso": "🇺🇸 USA (+1)",
        "phone_format_with_name": "🇺🇸 United States (+1)"
    },
    {
        "iso2": "GB",
        "iso3": "GBR",
        "name": "United Kingdom",
        "phone_code": "+44",
        "emoji": "🇬🇧",
        "phone_format_with_iso": "🇬🇧 GBR (+44)",
        "phone_format_with_name": "🇬🇧 United Kingdom (+44)"
    },
    {
        "iso2": "CA",
        "iso3": "CAN",
        "name": "Canada",
        "phone_code": "+1",
        "emoji": "🇨🇦",
        "phone_format_with_iso": "🇨🇦 CAN (+1)",
        "phone_format_with_name": "🇨🇦 Canada (+1)"
    },
    {
        "iso2": "IN",
        "iso3": "IND",
        "name": "India",
        "phone_code": "+91",
        "emoji": "🇮🇳",
        "phone_format_with_iso": "🇮🇳 IND (+91)",
        "phone_format_with_name": "🇮🇳 India (+91)"
    },
    {
        "iso2": "PK",
        "iso3": "PAK",
        "name": "Pakistan",
        "phone_code": "+92",
        "emoji": "🇵🇰",
        "phone_format_with_iso": "🇵🇰 PAK (+92)",
        "phone_format_with_name": "🇵🇰 Pakistan (+92)"
    },
    {
        "iso2": "FR",
        "iso3": "FRA",
        "name": "France",
        "phone_code": "+33",
        "emoji": "🇫🇷",
        "phone_format_with_iso": "🇫🇷 FRA (+33)",
        "phone_format_with_name": "🇫🇷 France (+33)"
    },
    {
        "iso2": "ES",
        "iso3": "ESP",
        "name": "Spain",
        "phone_code": "+34",
        "emoji": "🇪🇸",
        "phone_format_with_iso": "🇪🇸 ESP (+34)",
        "phone_format_with_name": "🇪🇸 Spain (+34)"
    },
    {
        "iso2": "IT",
        "iso3": "ITA",
        "name": "Italy",
        "phone_code": "+39",
        "emoji": "🇮🇹",
        "phone_format_with_iso": "🇮🇹 ITA (+39)",
        "phone_format_with_name": "🇮🇹 Italy (+39)"
    },
    {
        "iso2": "DE",
        "iso3": "DEU",
        "name": "Germany",
        "phone_code": "+49",
        "emoji": "🇩🇪",
        "phone_format_with_iso": "🇩🇪 DEU (+49)",
        "phone_format_with_name": "🇩🇪 Germany (+49)"
    },
    {
        "iso2": "BM",
        "iso3": "BMU",
        "name": "Bermuda",
        "phone_code": "+1-441",
        "emoji": "🇧🇲",
        "phone_format_with_iso": "🇧🇲 BMU (+1-441)",
        "phone_format_with_name": "🇧🇲 Bermuda (+1-441)"
    },
    {
        "iso2": "VC",
        "iso3": "VCT",
        "name": "Saint Vincent And The Grenadines",
        "phone_code": "+1-784",
        "emoji": "🇻🇨",
        "phone_format_with_iso": "🇻🇨 VCT (+1-784)",
        "phone_format_with_name": "🇻🇨 Saint Vincent And The Grenadines (+1-784)"
    },
    {
        "iso2": "LC",
        "iso3": "LCA",
        "name": "Saint Lucia",
        "phone_code": "+1-758",
        "emoji": "🇱🇨",
        "phone_format_with_iso": "🇱🇨 LCA (+1-758)",
        "phone_format_with_name": "🇱🇨 Saint Lucia (+1-758)"
    },
    {
        "iso2": "BS",
        "iso3": "BHS",
        "name": "Bahamas The",
        "phone_code": "+1-242",
        "emoji": "🇧🇸",
        "phone_format_with_iso": "🇧🇸 BHS (+1-242)",
        "phone_format_with_name": "🇧🇸 Bahamas The (+1-242)"
    },
    {
        "iso2": "VG",
        "iso3": "VGB",
        "name": "Virgin Islands (British)",
        "phone_code": "+1-284",
        "emoji": "🇻🇬",
        "phone_format_with_iso": "🇻🇬 VGB (+1-284)",
        "phone_format_with_name": "🇻🇬 Virgin Islands (British) (+1-284)"
    },
    {
        "iso2": "VI",
        "iso3": "VIR",
        "name": "Virgin Islands (US)",
        "phone_code": "+1-340",
        "emoji": "🇻🇮",
        "phone_format_with_iso": "🇻🇮 VIR (+1-340)",
        "phone_format_with_name": "🇻🇮 Virgin Islands (US) (+1-340)"
    },
    {
        "iso2": "KN",
        "iso3": "KNA",
        "name": "Saint Kitts And Nevis",
        "phone_code": "+1-869",
        "emoji": "🇰🇳",
        "phone_format_with_iso": "🇰🇳 KNA (+1-869)",
        "phone_format_with_name": "🇰🇳 Saint Kitts And Nevis (+1-869)"
    },
    {
        "iso2": "DM",
        "iso3": "DMA",
        "name": "Dominica",
        "phone_code": "+1-767",
        "emoji": "🇩🇲",
        "phone_format_with_iso": "🇩🇲 DMA (+1-767)",
        "phone_format_with_name": "🇩🇲 Dominica (+1-767)"
    },
    {
        "iso2": "DO",
        "iso3": "DOM",
        "name": "Dominican Republic",
        "phone_code": "+1-809 and 1-829",
        "emoji": "🇩🇴",
        "phone_format_with_iso": "🇩🇴 DOM (+1-809 and 1-829)",
        "phone_format_with_name": "🇩🇴 Dominican Republic (+1-809 and 1-829)"
    },
    {
        "iso2": "SX",
        "iso3": "SXM",
        "name": "Sint Maarten (Dutch part)",
        "phone_code": "+1721",
        "emoji": "🇸🇽",
        "phone_format_with_iso": "🇸🇽 SXM (+1721)",
        "phone_format_with_name": "🇸🇽 Sint Maarten (Dutch part) (+1721)"
    },
    {
        "iso2": "TC",
        "iso3": "TCA",
        "name": "Turks And Caicos Islands",
        "phone_code": "+1-649",
        "emoji": "🇹🇨",
        "phone_format_with_iso": "🇹🇨 TCA (+1-649)",
        "phone_format_with_name": "🇹🇨 Turks And Caicos Islands (+1-649)"
    },
    {
        "iso2": "JM",
        "iso3": "JAM",
        "name": "Jamaica",
        "phone_code": "+1-876",
        "emoji": "🇯🇲",
        "phone_format_with_iso": "🇯🇲 JAM (+1-876)",
        "phone_format_with_name": "🇯🇲 Jamaica (+1-876)"
    },
    {
        "iso2": "TT",
        "iso3": "TTO",
        "name": "Trinidad And Tobago",
        "phone_code": "+1-868",
        "emoji": "🇹🇹",
        "phone_format_with_iso": "🇹🇹 TTO (+1-868)",
        "phone_format_with_name": "🇹🇹 Trinidad And Tobago (+1-868)"
    },
    {
        "iso2": "AG",
        "iso3": "ATG",
        "name": "Antigua And Barbuda",
        "phone_code": "+1-268",
        "emoji": "🇦🇬",
        "phone_format_with_iso": "🇦🇬 ATG (+1-268)",
        "phone_format_with_name": "🇦🇬 Antigua And Barbuda (+1-268)"
    },
    {
        "iso2": "GD",
        "iso3": "GRD",
        "name": "Grenada",
        "phone_code": "+1-473",
        "emoji": "🇬🇩",
        "phone_format_with_iso": "🇬🇩 GRD (+1-473)",
        "phone_format_with_name": "🇬🇩 Grenada (+1-473)"
    },
    {
        "iso2": "PR",
        "iso3": "PRI",
        "name": "Puerto Rico",
        "phone_code": "+1-787 and 1-939",
        "emoji": "🇵🇷",
        "phone_format_with_iso": "🇵🇷 PRI (+1-787 and 1-939)",
        "phone_format_with_name": "🇵🇷 Puerto Rico (+1-787 and 1-939)"
    },
    {
        "iso2": "AI",
        "iso3": "AIA",
        "name": "Anguilla",
        "phone_code": "+1-264",
        "emoji": "🇦🇮",
        "phone_format_with_iso": "🇦🇮 AIA (+1-264)",
        "phone_format_with_name": "🇦🇮 Anguilla (+1-264)"
    },
    {
        "iso2": "MS",
        "iso3": "MSR",
        "name": "Montserrat",
        "phone_code": "+1-664",
        "emoji": "🇲🇸",
        "phone_format_with_iso": "🇲🇸 MSR (+1-664)",
        "phone_format_with_name": "🇲🇸 Montserrat (+1-664)"
    },
    {
        "iso2": "MP",
        "iso3": "MNP",
        "name": "Northern Mariana Islands",
        "phone_code": "+1-670",
        "emoji": "🇲🇵",
        "phone_format_with_iso": "🇲🇵 MNP (+1-670)",
        "phone_format_with_name": "🇲🇵 Northern Mariana Islands (+1-670)"
    },
    {
        "iso2": "KY",
        "iso3": "CYM",
        "name": "Cayman Islands",
        "phone_code": "+1-345",
        "emoji": "🇰🇾",
        "phone_format_with_iso": "🇰🇾 CYM (+1-345)",
        "phone_format_with_name": "🇰🇾 Cayman Islands (+1-345)"
    },




    {
        "iso2": "AF",
        "iso3": "AFG",
        "name": "Afghanistan",
        "phone_code": "+93",
        "emoji": "🇦🇫",
        "phone_format_with_iso": "🇦🇫 AFG (+93)",
        "phone_format_with_name": "🇦🇫 Afghanistan (+93)"
    },
    {
        "iso2": "AX",
        "iso3": "ALA",
        "name": "Aland Islands",
        "phone_code": "+358-18",
        "emoji": "🇦🇽",
        "phone_format_with_iso": "🇦🇽 ALA (+358-18)",
        "phone_format_with_name": "🇦🇽 Aland Islands (+358-18)"
    },
    {
        "iso2": "AL",
        "iso3": "ALB",
        "name": "Albania",
        "phone_code": "+355",
        "emoji": "🇦🇱",
        "phone_format_with_iso": "🇦🇱 ALB (+355)",
        "phone_format_with_name": "🇦🇱 Albania (+355)"
    },
    {
        "iso2": "DZ",
        "iso3": "DZA",
        "name": "Algeria",
        "phone_code": "+213",
        "emoji": "🇩🇿",
        "phone_format_with_iso": "🇩🇿 DZA (+213)",
        "phone_format_with_name": "🇩🇿 Algeria (+213)"
    },
    {
        "iso2": "AS",
        "iso3": "ASM",
        "name": "American Samoa",
        "phone_code": "+1-684",
        "emoji": "🇦🇸",
        "phone_format_with_iso": "🇦🇸 ASM (+1-684)",
        "phone_format_with_name": "🇦🇸 American Samoa (+1-684)"
    },
    {
        "iso2": "AD",
        "iso3": "AND",
        "name": "Andorra",
        "phone_code": "+376",
        "emoji": "🇦🇩",
        "phone_format_with_iso": "🇦🇩 AND (+376)",
        "phone_format_with_name": "🇦🇩 Andorra (+376)"
    },
    {
        "iso2": "AO",
        "iso3": "AGO",
        "name": "Angola",
        "phone_code": "+244",
        "emoji": "🇦🇴",
        "phone_format_with_iso": "🇦🇴 AGO (+244)",
        "phone_format_with_name": "🇦🇴 Angola (+244)"
    },

    {
        "iso2": "AQ",
        "iso3": "ATA",
        "name": "Antarctica",
        "phone_code": "+",
        "emoji": "🇦🇶",
        "phone_format_with_iso": "🇦🇶 ATA (+)",
        "phone_format_with_name": "🇦🇶 Antarctica (+)"
    },

    {
        "iso2": "AR",
        "iso3": "ARG",
        "name": "Argentina",
        "phone_code": "+54",
        "emoji": "🇦🇷",
        "phone_format_with_iso": "🇦🇷 ARG (+54)",
        "phone_format_with_name": "🇦🇷 Argentina (+54)"
    },
    {
        "iso2": "AM",
        "iso3": "ARM",
        "name": "Armenia",
        "phone_code": "+374",
        "emoji": "🇦🇲",
        "phone_format_with_iso": "🇦🇲 ARM (+374)",
        "phone_format_with_name": "🇦🇲 Armenia (+374)"
    },
    {
        "iso2": "AW",
        "iso3": "ABW",
        "name": "Aruba",
        "phone_code": "+297",
        "emoji": "🇦🇼",
        "phone_format_with_iso": "🇦🇼 ABW (+297)",
        "phone_format_with_name": "🇦🇼 Aruba (+297)"
    },
    {
        "iso2": "AU",
        "iso3": "AUS",
        "name": "Australia",
        "phone_code": "+61",
        "emoji": "🇦🇺",
        "phone_format_with_iso": "🇦🇺 AUS (+61)",
        "phone_format_with_name": "🇦🇺 Australia (+61)"
    },
    {
        "iso2": "AT",
        "iso3": "AUT",
        "name": "Austria",
        "phone_code": "+43",
        "emoji": "🇦🇹",
        "phone_format_with_iso": "🇦🇹 AUT (+43)",
        "phone_format_with_name": "🇦🇹 Austria (+43)"
    },
    {
        "iso2": "AZ",
        "iso3": "AZE",
        "name": "Azerbaijan",
        "phone_code": "+994",
        "emoji": "🇦🇿",
        "phone_format_with_iso": "🇦🇿 AZE (+994)",
        "phone_format_with_name": "🇦🇿 Azerbaijan (+994)"
    },

    {
        "iso2": "BH",
        "iso3": "BHR",
        "name": "Bahrain",
        "phone_code": "+973",
        "emoji": "🇧🇭",
        "phone_format_with_iso": "🇧🇭 BHR (+973)",
        "phone_format_with_name": "🇧🇭 Bahrain (+973)"
    },
    {
        "iso2": "BD",
        "iso3": "BGD",
        "name": "Bangladesh",
        "phone_code": "+880",
        "emoji": "🇧🇩",
        "phone_format_with_iso": "🇧🇩 BGD (+880)",
        "phone_format_with_name": "🇧🇩 Bangladesh (+880)"
    },
    {
        "iso2": "BB",
        "iso3": "BRB",
        "name": "Barbados",
        "phone_code": "+1-246",
        "emoji": "🇧🇧",
        "phone_format_with_iso": "🇧🇧 BRB (+1-246)",
        "phone_format_with_name": "🇧🇧 Barbados (+1-246)"
    },
    {
        "iso2": "BY",
        "iso3": "BLR",
        "name": "Belarus",
        "phone_code": "+375",
        "emoji": "🇧🇾",
        "phone_format_with_iso": "🇧🇾 BLR (+375)",
        "phone_format_with_name": "🇧🇾 Belarus (+375)"
    },
    {
        "iso2": "BE",
        "iso3": "BEL",
        "name": "Belgium",
        "phone_code": "+32",
        "emoji": "🇧🇪",
        "phone_format_with_iso": "🇧🇪 BEL (+32)",
        "phone_format_with_name": "🇧🇪 Belgium (+32)"
    },
    {
        "iso2": "BZ",
        "iso3": "BLZ",
        "name": "Belize",
        "phone_code": "+501",
        "emoji": "🇧🇿",
        "phone_format_with_iso": "🇧🇿 BLZ (+501)",
        "phone_format_with_name": "🇧🇿 Belize (+501)"
    },
    {
        "iso2": "BJ",
        "iso3": "BEN",
        "name": "Benin",
        "phone_code": "+229",
        "emoji": "🇧🇯",
        "phone_format_with_iso": "🇧🇯 BEN (+229)",
        "phone_format_with_name": "🇧🇯 Benin (+229)"
    },

    {
        "iso2": "BT",
        "iso3": "BTN",
        "name": "Bhutan",
        "phone_code": "+975",
        "emoji": "🇧🇹",
        "phone_format_with_iso": "🇧🇹 BTN (+975)",
        "phone_format_with_name": "🇧🇹 Bhutan (+975)"
    },
    {
        "iso2": "BO",
        "iso3": "BOL",
        "name": "Bolivia",
        "phone_code": "+591",
        "emoji": "🇧🇴",
        "phone_format_with_iso": "🇧🇴 BOL (+591)",
        "phone_format_with_name": "🇧🇴 Bolivia (+591)"
    },
    {
        "iso2": "BA",
        "iso3": "BIH",
        "name": "Bosnia and Herzegovina",
        "phone_code": "+387",
        "emoji": "🇧🇦",
        "phone_format_with_iso": "🇧🇦 BIH (+387)",
        "phone_format_with_name": "🇧🇦 Bosnia and Herzegovina (+387)"
    },
    {
        "iso2": "BW",
        "iso3": "BWA",
        "name": "Botswana",
        "phone_code": "+267",
        "emoji": "🇧🇼",
        "phone_format_with_iso": "🇧🇼 BWA (+267)",
        "phone_format_with_name": "🇧🇼 Botswana (+267)"
    },
    {
        "iso2": "BV",
        "iso3": "BVT",
        "name": "Bouvet Island",
        "phone_code": "+0055",
        "emoji": "🇧🇻",
        "phone_format_with_iso": "🇧🇻 BVT (+0055)",
        "phone_format_with_name": "🇧🇻 Bouvet Island (+0055)"
    },
    {
        "iso2": "BR",
        "iso3": "BRA",
        "name": "Brazil",
        "phone_code": "+55",
        "emoji": "🇧🇷",
        "phone_format_with_iso": "🇧🇷 BRA (+55)",
        "phone_format_with_name": "🇧🇷 Brazil (+55)"
    },
    {
        "iso2": "IO",
        "iso3": "IOT",
        "name": "British Indian Ocean Territory",
        "phone_code": "+246",
        "emoji": "🇮🇴",
        "phone_format_with_iso": "🇮🇴 IOT (+246)",
        "phone_format_with_name": "🇮🇴 British Indian Ocean Territory (+246)"
    },
    {
        "iso2": "BN",
        "iso3": "BRN",
        "name": "Brunei",
        "phone_code": "+673",
        "emoji": "🇧🇳",
        "phone_format_with_iso": "🇧🇳 BRN (+673)",
        "phone_format_with_name": "🇧🇳 Brunei (+673)"
    },
    {
        "iso2": "BG",
        "iso3": "BGR",
        "name": "Bulgaria",
        "phone_code": "+359",
        "emoji": "🇧🇬",
        "phone_format_with_iso": "🇧🇬 BGR (+359)",
        "phone_format_with_name": "🇧🇬 Bulgaria (+359)"
    },
    {
        "iso2": "BF",
        "iso3": "BFA",
        "name": "Burkina Faso",
        "phone_code": "+226",
        "emoji": "🇧🇫",
        "phone_format_with_iso": "🇧🇫 BFA (+226)",
        "phone_format_with_name": "🇧🇫 Burkina Faso (+226)"
    },
    {
        "iso2": "BI",
        "iso3": "BDI",
        "name": "Burundi",
        "phone_code": "+257",
        "emoji": "🇧🇮",
        "phone_format_with_iso": "🇧🇮 BDI (+257)",
        "phone_format_with_name": "🇧🇮 Burundi (+257)"
    },
    {
        "iso2": "KH",
        "iso3": "KHM",
        "name": "Cambodia",
        "phone_code": "+855",
        "emoji": "🇰🇭",
        "phone_format_with_iso": "🇰🇭 KHM (+855)",
        "phone_format_with_name": "🇰🇭 Cambodia (+855)"
    },
    {
        "iso2": "CM",
        "iso3": "CMR",
        "name": "Cameroon",
        "phone_code": "+237",
        "emoji": "🇨🇲",
        "phone_format_with_iso": "🇨🇲 CMR (+237)",
        "phone_format_with_name": "🇨🇲 Cameroon (+237)"
    },

    {
        "iso2": "CV",
        "iso3": "CPV",
        "name": "Cape Verde",
        "phone_code": "+238",
        "emoji": "🇨🇻",
        "phone_format_with_iso": "🇨🇻 CPV (+238)",
        "phone_format_with_name": "🇨🇻 Cape Verde (+238)"
    },

    {
        "iso2": "CF",
        "iso3": "CAF",
        "name": "Central African Republic",
        "phone_code": "+236",
        "emoji": "🇨🇫",
        "phone_format_with_iso": "🇨🇫 CAF (+236)",
        "phone_format_with_name": "🇨🇫 Central African Republic (+236)"
    },
    {
        "iso2": "TD",
        "iso3": "TCD",
        "name": "Chad",
        "phone_code": "+235",
        "emoji": "🇹🇩",
        "phone_format_with_iso": "🇹🇩 TCD (+235)",
        "phone_format_with_name": "🇹🇩 Chad (+235)"
    },
    {
        "iso2": "CL",
        "iso3": "CHL",
        "name": "Chile",
        "phone_code": "+56",
        "emoji": "🇨🇱",
        "phone_format_with_iso": "🇨🇱 CHL (+56)",
        "phone_format_with_name": "🇨🇱 Chile (+56)"
    },
    {
        "iso2": "CN",
        "iso3": "CHN",
        "name": "China",
        "phone_code": "+86",
        "emoji": "🇨🇳",
        "phone_format_with_iso": "🇨🇳 CHN (+86)",
        "phone_format_with_name": "🇨🇳 China (+86)"
    },
    {
        "iso2": "CX",
        "iso3": "CXR",
        "name": "Christmas Island",
        "phone_code": "+61",
        "emoji": "🇨🇽",
        "phone_format_with_iso": "🇨🇽 CXR (+61)",
        "phone_format_with_name": "🇨🇽 Christmas Island (+61)"
    },
    {
        "iso2": "CC",
        "iso3": "CCK",
        "name": "Cocos (Keeling) Islands",
        "phone_code": "+61",
        "emoji": "🇨🇨",
        "phone_format_with_iso": "🇨🇨 CCK (+61)",
        "phone_format_with_name": "🇨🇨 Cocos (Keeling) Islands (+61)"
    },
    {
        "iso2": "CO",
        "iso3": "COL",
        "name": "Colombia",
        "phone_code": "+57",
        "emoji": "🇨🇴",
        "phone_format_with_iso": "🇨🇴 COL (+57)",
        "phone_format_with_name": "🇨🇴 Colombia (+57)"
    },
    {
        "iso2": "KM",
        "iso3": "COM",
        "name": "Comoros",
        "phone_code": "+269",
        "emoji": "🇰🇲",
        "phone_format_with_iso": "🇰🇲 COM (+269)",
        "phone_format_with_name": "🇰🇲 Comoros (+269)"
    },
    {
        "iso2": "CG",
        "iso3": "COG",
        "name": "Congo",
        "phone_code": "+242",
        "emoji": "🇨🇬",
        "phone_format_with_iso": "🇨🇬 COG (+242)",
        "phone_format_with_name": "🇨🇬 Congo (+242)"
    },
    {
        "iso2": "CD",
        "iso3": "COD",
        "name": "Congo The Democratic Republic Of The",
        "phone_code": "+243",
        "emoji": "🇨🇩",
        "phone_format_with_iso": "🇨🇩 COD (+243)",
        "phone_format_with_name": "🇨🇩 Congo The Democratic Republic Of The (+243)"
    },
    {
        "iso2": "CK",
        "iso3": "COK",
        "name": "Cook Islands",
        "phone_code": "+682",
        "emoji": "🇨🇰",
        "phone_format_with_iso": "🇨🇰 COK (+682)",
        "phone_format_with_name": "🇨🇰 Cook Islands (+682)"
    },
    {
        "iso2": "CR",
        "iso3": "CRI",
        "name": "Costa Rica",
        "phone_code": "+506",
        "emoji": "🇨🇷",
        "phone_format_with_iso": "🇨🇷 CRI (+506)",
        "phone_format_with_name": "🇨🇷 Costa Rica (+506)"
    },
    {
        "iso2": "CI",
        "iso3": "CIV",
        "name": "Cote D'Ivoire (Ivory Coast)",
        "phone_code": "+225",
        "emoji": "🇨🇮",
        "phone_format_with_iso": "🇨🇮 CIV (+225)",
        "phone_format_with_name": "🇨🇮 Cote D'Ivoire (Ivory Coast) (+225)"
    },
    {
        "iso2": "HR",
        "iso3": "HRV",
        "name": "Croatia (Hrvatska)",
        "phone_code": "+385",
        "emoji": "🇭🇷",
        "phone_format_with_iso": "🇭🇷 HRV (+385)",
        "phone_format_with_name": "🇭🇷 Croatia (Hrvatska) (+385)"
    },
    {
        "iso2": "CU",
        "iso3": "CUB",
        "name": "Cuba",
        "phone_code": "+53",
        "emoji": "🇨🇺",
        "phone_format_with_iso": "🇨🇺 CUB (+53)",
        "phone_format_with_name": "🇨🇺 Cuba (+53)"
    },
    {
        "iso2": "CY",
        "iso3": "CYP",
        "name": "Cyprus",
        "phone_code": "+357",
        "emoji": "🇨🇾",
        "phone_format_with_iso": "🇨🇾 CYP (+357)",
        "phone_format_with_name": "🇨🇾 Cyprus (+357)"
    },
    {
        "iso2": "CZ",
        "iso3": "CZE",
        "name": "Czech Republic",
        "phone_code": "+420",
        "emoji": "🇨🇿",
        "phone_format_with_iso": "🇨🇿 CZE (+420)",
        "phone_format_with_name": "🇨🇿 Czech Republic (+420)"
    },
    {
        "iso2": "DK",
        "iso3": "DNK",
        "name": "Denmark",
        "phone_code": "+45",
        "emoji": "🇩🇰",
        "phone_format_with_iso": "🇩🇰 DNK (+45)",
        "phone_format_with_name": "🇩🇰 Denmark (+45)"
    },
    {
        "iso2": "DJ",
        "iso3": "DJI",
        "name": "Djibouti",
        "phone_code": "+253",
        "emoji": "🇩🇯",
        "phone_format_with_iso": "🇩🇯 DJI (+253)",
        "phone_format_with_name": "🇩🇯 Djibouti (+253)"
    },

    {
        "iso2": "TL",
        "iso3": "TLS",
        "name": "East Timor",
        "phone_code": "+670",
        "emoji": "🇹🇱",
        "phone_format_with_iso": "🇹🇱 TLS (+670)",
        "phone_format_with_name": "🇹🇱 East Timor (+670)"
    },
    {
        "iso2": "EC",
        "iso3": "ECU",
        "name": "Ecuador",
        "phone_code": "+593",
        "emoji": "🇪🇨",
        "phone_format_with_iso": "🇪🇨 ECU (+593)",
        "phone_format_with_name": "🇪🇨 Ecuador (+593)"
    },
    {
        "iso2": "EG",
        "iso3": "EGY",
        "name": "Egypt",
        "phone_code": "+20",
        "emoji": "🇪🇬",
        "phone_format_with_iso": "🇪🇬 EGY (+20)",
        "phone_format_with_name": "🇪🇬 Egypt (+20)"
    },
    {
        "iso2": "SV",
        "iso3": "SLV",
        "name": "El Salvador",
        "phone_code": "+503",
        "emoji": "🇸🇻",
        "phone_format_with_iso": "🇸🇻 SLV (+503)",
        "phone_format_with_name": "🇸🇻 El Salvador (+503)"
    },
    {
        "iso2": "GQ",
        "iso3": "GNQ",
        "name": "Equatorial Guinea",
        "phone_code": "+240",
        "emoji": "🇬🇶",
        "phone_format_with_iso": "🇬🇶 GNQ (+240)",
        "phone_format_with_name": "🇬🇶 Equatorial Guinea (+240)"
    },
    {
        "iso2": "ER",
        "iso3": "ERI",
        "name": "Eritrea",
        "phone_code": "+291",
        "emoji": "🇪🇷",
        "phone_format_with_iso": "🇪🇷 ERI (+291)",
        "phone_format_with_name": "🇪🇷 Eritrea (+291)"
    },
    {
        "iso2": "EE",
        "iso3": "EST",
        "name": "Estonia",
        "phone_code": "+372",
        "emoji": "🇪🇪",
        "phone_format_with_iso": "🇪🇪 EST (+372)",
        "phone_format_with_name": "🇪🇪 Estonia (+372)"
    },
    {
        "iso2": "ET",
        "iso3": "ETH",
        "name": "Ethiopia",
        "phone_code": "+251",
        "emoji": "🇪🇹",
        "phone_format_with_iso": "🇪🇹 ETH (+251)",
        "phone_format_with_name": "🇪🇹 Ethiopia (+251)"
    },
    {
        "iso2": "FK",
        "iso3": "FLK",
        "name": "Falkland Islands",
        "phone_code": "+500",
        "emoji": "🇫🇰",
        "phone_format_with_iso": "🇫🇰 FLK (+500)",
        "phone_format_with_name": "🇫🇰 Falkland Islands (+500)"
    },
    {
        "iso2": "FO",
        "iso3": "FRO",
        "name": "Faroe Islands",
        "phone_code": "+298",
        "emoji": "🇫🇴",
        "phone_format_with_iso": "🇫🇴 FRO (+298)",
        "phone_format_with_name": "🇫🇴 Faroe Islands (+298)"
    },
    {
        "iso2": "FJ",
        "iso3": "FJI",
        "name": "Fiji Islands",
        "phone_code": "+679",
        "emoji": "🇫🇯",
        "phone_format_with_iso": "🇫🇯 FJI (+679)",
        "phone_format_with_name": "🇫🇯 Fiji Islands (+679)"
    },
    {
        "iso2": "FI",
        "iso3": "FIN",
        "name": "Finland",
        "phone_code": "+358",
        "emoji": "🇫🇮",
        "phone_format_with_iso": "🇫🇮 FIN (+358)",
        "phone_format_with_name": "🇫🇮 Finland (+358)"
    },

    {
        "iso2": "GF",
        "iso3": "GUF",
        "name": "French Guiana",
        "phone_code": "+594",
        "emoji": "🇬🇫",
        "phone_format_with_iso": "🇬🇫 GUF (+594)",
        "phone_format_with_name": "🇬🇫 French Guiana (+594)"
    },
    {
        "iso2": "PF",
        "iso3": "PYF",
        "name": "French Polynesia",
        "phone_code": "+689",
        "emoji": "🇵🇫",
        "phone_format_with_iso": "🇵🇫 PYF (+689)",
        "phone_format_with_name": "🇵🇫 French Polynesia (+689)"
    },
    {
        "iso2": "TF",
        "iso3": "ATF",
        "name": "French Southern Territories",
        "phone_code": "+",
        "emoji": "🇹🇫",
        "phone_format_with_iso": "🇹🇫 ATF (+)",
        "phone_format_with_name": "🇹🇫 French Southern Territories (+)"
    },
    {
        "iso2": "GA",
        "iso3": "GAB",
        "name": "Gabon",
        "phone_code": "+241",
        "emoji": "🇬🇦",
        "phone_format_with_iso": "🇬🇦 GAB (+241)",
        "phone_format_with_name": "🇬🇦 Gabon (+241)"
    },
    {
        "iso2": "GM",
        "iso3": "GMB",
        "name": "Gambia The",
        "phone_code": "+220",
        "emoji": "🇬🇲",
        "phone_format_with_iso": "🇬🇲 GMB (+220)",
        "phone_format_with_name": "🇬🇲 Gambia The (+220)"
    },
    {
        "iso2": "GE",
        "iso3": "GEO",
        "name": "Georgia",
        "phone_code": "+995",
        "emoji": "🇬🇪",
        "phone_format_with_iso": "🇬🇪 GEO (+995)",
        "phone_format_with_name": "🇬🇪 Georgia (+995)"
    },

    {
        "iso2": "GH",
        "iso3": "GHA",
        "name": "Ghana",
        "phone_code": "+233",
        "emoji": "🇬🇭",
        "phone_format_with_iso": "🇬🇭 GHA (+233)",
        "phone_format_with_name": "🇬🇭 Ghana (+233)"
    },
    {
        "iso2": "GI",
        "iso3": "GIB",
        "name": "Gibraltar",
        "phone_code": "+350",
        "emoji": "🇬🇮",
        "phone_format_with_iso": "🇬🇮 GIB (+350)",
        "phone_format_with_name": "🇬🇮 Gibraltar (+350)"
    },
    {
        "iso2": "GR",
        "iso3": "GRC",
        "name": "Greece",
        "phone_code": "+30",
        "emoji": "🇬🇷",
        "phone_format_with_iso": "🇬🇷 GRC (+30)",
        "phone_format_with_name": "🇬🇷 Greece (+30)"
    },
    {
        "iso2": "GL",
        "iso3": "GRL",
        "name": "Greenland",
        "phone_code": "+299",
        "emoji": "🇬🇱",
        "phone_format_with_iso": "🇬🇱 GRL (+299)",
        "phone_format_with_name": "🇬🇱 Greenland (+299)"
    },

    {
        "iso2": "GP",
        "iso3": "GLP",
        "name": "Guadeloupe",
        "phone_code": "+590",
        "emoji": "🇬🇵",
        "phone_format_with_iso": "🇬🇵 GLP (+590)",
        "phone_format_with_name": "🇬🇵 Guadeloupe (+590)"
    },
    {
        "iso2": "GU",
        "iso3": "GUM",
        "name": "Guam",
        "phone_code": "+1-671",
        "emoji": "🇬🇺",
        "phone_format_with_iso": "🇬🇺 GUM (+1-671)",
        "phone_format_with_name": "🇬🇺 Guam (+1-671)"
    },
    {
        "iso2": "GT",
        "iso3": "GTM",
        "name": "Guatemala",
        "phone_code": "+502",
        "emoji": "🇬🇹",
        "phone_format_with_iso": "🇬🇹 GTM (+502)",
        "phone_format_with_name": "🇬🇹 Guatemala (+502)"
    },
    {
        "iso2": "GG",
        "iso3": "GGY",
        "name": "Guernsey and Alderney",
        "phone_code": "+44-1481",
        "emoji": "🇬🇬",
        "phone_format_with_iso": "🇬🇬 GGY (+44-1481)",
        "phone_format_with_name": "🇬🇬 Guernsey and Alderney (+44-1481)"
    },
    {
        "iso2": "GN",
        "iso3": "GIN",
        "name": "Guinea",
        "phone_code": "+224",
        "emoji": "🇬🇳",
        "phone_format_with_iso": "🇬🇳 GIN (+224)",
        "phone_format_with_name": "🇬🇳 Guinea (+224)"
    },
    {
        "iso2": "GW",
        "iso3": "GNB",
        "name": "Guinea-Bissau",
        "phone_code": "+245",
        "emoji": "🇬🇼",
        "phone_format_with_iso": "🇬🇼 GNB (+245)",
        "phone_format_with_name": "🇬🇼 Guinea-Bissau (+245)"
    },
    {
        "iso2": "GY",
        "iso3": "GUY",
        "name": "Guyana",
        "phone_code": "+592",
        "emoji": "🇬🇾",
        "phone_format_with_iso": "🇬🇾 GUY (+592)",
        "phone_format_with_name": "🇬🇾 Guyana (+592)"
    },
    {
        "iso2": "HT",
        "iso3": "HTI",
        "name": "Haiti",
        "phone_code": "+509",
        "emoji": "🇭🇹",
        "phone_format_with_iso": "🇭🇹 HTI (+509)",
        "phone_format_with_name": "🇭🇹 Haiti (+509)"
    },
    {
        "iso2": "HM",
        "iso3": "HMD",
        "name": "Heard Island and McDonald Islands",
        "phone_code": "+",
        "emoji": "🇭🇲",
        "phone_format_with_iso": "🇭🇲 HMD (+)",
        "phone_format_with_name": "🇭🇲 Heard Island and McDonald Islands (+)"
    },
    {
        "iso2": "HN",
        "iso3": "HND",
        "name": "Honduras",
        "phone_code": "+504",
        "emoji": "🇭🇳",
        "phone_format_with_iso": "🇭🇳 HND (+504)",
        "phone_format_with_name": "🇭🇳 Honduras (+504)"
    },
    {
        "iso2": "HK",
        "iso3": "HKG",
        "name": "Hong Kong S.A.R.",
        "phone_code": "+852",
        "emoji": "🇭🇰",
        "phone_format_with_iso": "🇭🇰 HKG (+852)",
        "phone_format_with_name": "🇭🇰 Hong Kong S.A.R. (+852)"
    },
    {
        "iso2": "HU",
        "iso3": "HUN",
        "name": "Hungary",
        "phone_code": "+36",
        "emoji": "🇭🇺",
        "phone_format_with_iso": "🇭🇺 HUN (+36)",
        "phone_format_with_name": "🇭🇺 Hungary (+36)"
    },
    {
        "iso2": "IS",
        "iso3": "ISL",
        "name": "Iceland",
        "phone_code": "+354",
        "emoji": "🇮🇸",
        "phone_format_with_iso": "🇮🇸 ISL (+354)",
        "phone_format_with_name": "🇮🇸 Iceland (+354)"
    },

    {
        "iso2": "ID",
        "iso3": "IDN",
        "name": "Indonesia",
        "phone_code": "+62",
        "emoji": "🇮🇩",
        "phone_format_with_iso": "🇮🇩 IDN (+62)",
        "phone_format_with_name": "🇮🇩 Indonesia (+62)"
    },
    {
        "iso2": "IR",
        "iso3": "IRN",
        "name": "Iran",
        "phone_code": "+98",
        "emoji": "🇮🇷",
        "phone_format_with_iso": "🇮🇷 IRN (+98)",
        "phone_format_with_name": "🇮🇷 Iran (+98)"
    },
    {
        "iso2": "IQ",
        "iso3": "IRQ",
        "name": "Iraq",
        "phone_code": "+964",
        "emoji": "🇮🇶",
        "phone_format_with_iso": "🇮🇶 IRQ (+964)",
        "phone_format_with_name": "🇮🇶 Iraq (+964)"
    },
    {
        "iso2": "IE",
        "iso3": "IRL",
        "name": "Ireland",
        "phone_code": "+353",
        "emoji": "🇮🇪",
        "phone_format_with_iso": "🇮🇪 IRL (+353)",
        "phone_format_with_name": "🇮🇪 Ireland (+353)"
    },
    {
        "iso2": "IL",
        "iso3": "ISR",
        "name": "Israel",
        "phone_code": "+972",
        "emoji": "🇮🇱",
        "phone_format_with_iso": "🇮🇱 ISR (+972)",
        "phone_format_with_name": "🇮🇱 Israel (+972)"
    },
    {
        "iso2": "JP",
        "iso3": "JPN",
        "name": "Japan",
        "phone_code": "+81",
        "emoji": "🇯🇵",
        "phone_format_with_iso": "🇯🇵 JPN (+81)",
        "phone_format_with_name": "🇯🇵 Japan (+81)"
    },
    {
        "iso2": "JE",
        "iso3": "JEY",
        "name": "Jersey",
        "phone_code": "+44-1534",
        "emoji": "🇯🇪",
        "phone_format_with_iso": "🇯🇪 JEY (+44-1534)",
        "phone_format_with_name": "🇯🇪 Jersey (+44-1534)"
    },
    {
        "iso2": "JO",
        "iso3": "JOR",
        "name": "Jordan",
        "phone_code": "+962",
        "emoji": "🇯🇴",
        "phone_format_with_iso": "🇯🇴 JOR (+962)",
        "phone_format_with_name": "🇯🇴 Jordan (+962)"
    },
    {
        "iso2": "KZ",
        "iso3": "KAZ",
        "name": "Kazakhstan",
        "phone_code": "+7",
        "emoji": "🇰🇿",
        "phone_format_with_iso": "🇰🇿 KAZ (+7)",
        "phone_format_with_name": "🇰🇿 Kazakhstan (+7)"
    },
    {
        "iso2": "KE",
        "iso3": "KEN",
        "name": "Kenya",
        "phone_code": "+254",
        "emoji": "🇰🇪",
        "phone_format_with_iso": "🇰🇪 KEN (+254)",
        "phone_format_with_name": "🇰🇪 Kenya (+254)"
    },
    {
        "iso2": "KI",
        "iso3": "KIR",
        "name": "Kiribati",
        "phone_code": "+686",
        "emoji": "🇰🇮",
        "phone_format_with_iso": "🇰🇮 KIR (+686)",
        "phone_format_with_name": "🇰🇮 Kiribati (+686)"
    },
    {
        "iso2": "KP",
        "iso3": "PRK",
        "name": "Korea North",
        "phone_code": "+850",
        "emoji": "🇰🇵",
        "phone_format_with_iso": "🇰🇵 PRK (+850)",
        "phone_format_with_name": "🇰🇵 Korea North (+850)"
    },
    {
        "iso2": "KR",
        "iso3": "KOR",
        "name": "Korea South",
        "phone_code": "+82",
        "emoji": "🇰🇷",
        "phone_format_with_iso": "🇰🇷 KOR (+82)",
        "phone_format_with_name": "🇰🇷 Korea South (+82)"
    },
    {
        "iso2": "KW",
        "iso3": "KWT",
        "name": "Kuwait",
        "phone_code": "+965",
        "emoji": "🇰🇼",
        "phone_format_with_iso": "🇰🇼 KWT (+965)",
        "phone_format_with_name": "🇰🇼 Kuwait (+965)"
    },
    {
        "iso2": "KG",
        "iso3": "KGZ",
        "name": "Kyrgyzstan",
        "phone_code": "+996",
        "emoji": "🇰🇬",
        "phone_format_with_iso": "🇰🇬 KGZ (+996)",
        "phone_format_with_name": "🇰🇬 Kyrgyzstan (+996)"
    },
    {
        "iso2": "LA",
        "iso3": "LAO",
        "name": "Laos",
        "phone_code": "+856",
        "emoji": "🇱🇦",
        "phone_format_with_iso": "🇱🇦 LAO (+856)",
        "phone_format_with_name": "🇱🇦 Laos (+856)"
    },
    {
        "iso2": "LV",
        "iso3": "LVA",
        "name": "Latvia",
        "phone_code": "+371",
        "emoji": "🇱🇻",
        "phone_format_with_iso": "🇱🇻 LVA (+371)",
        "phone_format_with_name": "🇱🇻 Latvia (+371)"
    },
    {
        "iso2": "LB",
        "iso3": "LBN",
        "name": "Lebanon",
        "phone_code": "+961",
        "emoji": "🇱🇧",
        "phone_format_with_iso": "🇱🇧 LBN (+961)",
        "phone_format_with_name": "🇱🇧 Lebanon (+961)"
    },
    {
        "iso2": "LS",
        "iso3": "LSO",
        "name": "Lesotho",
        "phone_code": "+266",
        "emoji": "🇱🇸",
        "phone_format_with_iso": "🇱🇸 LSO (+266)",
        "phone_format_with_name": "🇱🇸 Lesotho (+266)"
    },
    {
        "iso2": "LR",
        "iso3": "LBR",
        "name": "Liberia",
        "phone_code": "+231",
        "emoji": "🇱🇷",
        "phone_format_with_iso": "🇱🇷 LBR (+231)",
        "phone_format_with_name": "🇱🇷 Liberia (+231)"
    },
    {
        "iso2": "LY",
        "iso3": "LBY",
        "name": "Libya",
        "phone_code": "+218",
        "emoji": "🇱🇾",
        "phone_format_with_iso": "🇱🇾 LBY (+218)",
        "phone_format_with_name": "🇱🇾 Libya (+218)"
    },
    {
        "iso2": "LI",
        "iso3": "LIE",
        "name": "Liechtenstein",
        "phone_code": "+423",
        "emoji": "🇱🇮",
        "phone_format_with_iso": "🇱🇮 LIE (+423)",
        "phone_format_with_name": "🇱🇮 Liechtenstein (+423)"
    },
    {
        "iso2": "LT",
        "iso3": "LTU",
        "name": "Lithuania",
        "phone_code": "+370",
        "emoji": "🇱🇹",
        "phone_format_with_iso": "🇱🇹 LTU (+370)",
        "phone_format_with_name": "🇱🇹 Lithuania (+370)"
    },
    {
        "iso2": "LU",
        "iso3": "LUX",
        "name": "Luxembourg",
        "phone_code": "+352",
        "emoji": "🇱🇺",
        "phone_format_with_iso": "🇱🇺 LUX (+352)",
        "phone_format_with_name": "🇱🇺 Luxembourg (+352)"
    },
    {
        "iso2": "MO",
        "iso3": "MAC",
        "name": "Macau S.A.R.",
        "phone_code": "+853",
        "emoji": "🇲🇴",
        "phone_format_with_iso": "🇲🇴 MAC (+853)",
        "phone_format_with_name": "🇲🇴 Macau S.A.R. (+853)"
    },
    {
        "iso2": "MK",
        "iso3": "MKD",
        "name": "Macedonia",
        "phone_code": "+389",
        "emoji": "🇲🇰",
        "phone_format_with_iso": "🇲🇰 MKD (+389)",
        "phone_format_with_name": "🇲🇰 Macedonia (+389)"
    },
    {
        "iso2": "MG",
        "iso3": "MDG",
        "name": "Madagascar",
        "phone_code": "+261",
        "emoji": "🇲🇬",
        "phone_format_with_iso": "🇲🇬 MDG (+261)",
        "phone_format_with_name": "🇲🇬 Madagascar (+261)"
    },
    {
        "iso2": "MW",
        "iso3": "MWI",
        "name": "Malawi",
        "phone_code": "+265",
        "emoji": "🇲🇼",
        "phone_format_with_iso": "🇲🇼 MWI (+265)",
        "phone_format_with_name": "🇲🇼 Malawi (+265)"
    },
    {
        "iso2": "MY",
        "iso3": "MYS",
        "name": "Malaysia",
        "phone_code": "+60",
        "emoji": "🇲🇾",
        "phone_format_with_iso": "🇲🇾 MYS (+60)",
        "phone_format_with_name": "🇲🇾 Malaysia (+60)"
    },
    {
        "iso2": "MV",
        "iso3": "MDV",
        "name": "Maldives",
        "phone_code": "+960",
        "emoji": "🇲🇻",
        "phone_format_with_iso": "🇲🇻 MDV (+960)",
        "phone_format_with_name": "🇲🇻 Maldives (+960)"
    },
    {
        "iso2": "ML",
        "iso3": "MLI",
        "name": "Mali",
        "phone_code": "+223",
        "emoji": "🇲🇱",
        "phone_format_with_iso": "🇲🇱 MLI (+223)",
        "phone_format_with_name": "🇲🇱 Mali (+223)"
    },
    {
        "iso2": "MT",
        "iso3": "MLT",
        "name": "Malta",
        "phone_code": "+356",
        "emoji": "🇲🇹",
        "phone_format_with_iso": "🇲🇹 MLT (+356)",
        "phone_format_with_name": "🇲🇹 Malta (+356)"
    },
    {
        "iso2": "IM",
        "iso3": "IMN",
        "name": "Man (Isle of)",
        "phone_code": "+44-1624",
        "emoji": "🇮🇲",
        "phone_format_with_iso": "🇮🇲 IMN (+44-1624)",
        "phone_format_with_name": "🇮🇲 Man (Isle of) (+44-1624)"
    },
    {
        "iso2": "MH",
        "iso3": "MHL",
        "name": "Marshall Islands",
        "phone_code": "+692",
        "emoji": "🇲🇭",
        "phone_format_with_iso": "🇲🇭 MHL (+692)",
        "phone_format_with_name": "🇲🇭 Marshall Islands (+692)"
    },
    {
        "iso2": "MQ",
        "iso3": "MTQ",
        "name": "Martinique",
        "phone_code": "+596",
        "emoji": "🇲🇶",
        "phone_format_with_iso": "🇲🇶 MTQ (+596)",
        "phone_format_with_name": "🇲🇶 Martinique (+596)"
    },
    {
        "iso2": "MR",
        "iso3": "MRT",
        "name": "Mauritania",
        "phone_code": "+222",
        "emoji": "🇲🇷",
        "phone_format_with_iso": "🇲🇷 MRT (+222)",
        "phone_format_with_name": "🇲🇷 Mauritania (+222)"
    },
    {
        "iso2": "MU",
        "iso3": "MUS",
        "name": "Mauritius",
        "phone_code": "+230",
        "emoji": "🇲🇺",
        "phone_format_with_iso": "🇲🇺 MUS (+230)",
        "phone_format_with_name": "🇲🇺 Mauritius (+230)"
    },
    {
        "iso2": "YT",
        "iso3": "MYT",
        "name": "Mayotte",
        "phone_code": "+262",
        "emoji": "🇾🇹",
        "phone_format_with_iso": "🇾🇹 MYT (+262)",
        "phone_format_with_name": "🇾🇹 Mayotte (+262)"
    },
    {
        "iso2": "MX",
        "iso3": "MEX",
        "name": "Mexico",
        "phone_code": "+52",
        "emoji": "🇲🇽",
        "phone_format_with_iso": "🇲🇽 MEX (+52)",
        "phone_format_with_name": "🇲🇽 Mexico (+52)"
    },
    {
        "iso2": "FM",
        "iso3": "FSM",
        "name": "Micronesia",
        "phone_code": "+691",
        "emoji": "🇫🇲",
        "phone_format_with_iso": "🇫🇲 FSM (+691)",
        "phone_format_with_name": "🇫🇲 Micronesia (+691)"
    },
    {
        "iso2": "MD",
        "iso3": "MDA",
        "name": "Moldova",
        "phone_code": "+373",
        "emoji": "🇲🇩",
        "phone_format_with_iso": "🇲🇩 MDA (+373)",
        "phone_format_with_name": "🇲🇩 Moldova (+373)"
    },
    {
        "iso2": "MC",
        "iso3": "MCO",
        "name": "Monaco",
        "phone_code": "+377",
        "emoji": "🇲🇨",
        "phone_format_with_iso": "🇲🇨 MCO (+377)",
        "phone_format_with_name": "🇲🇨 Monaco (+377)"
    },
    {
        "iso2": "MN",
        "iso3": "MNG",
        "name": "Mongolia",
        "phone_code": "+976",
        "emoji": "🇲🇳",
        "phone_format_with_iso": "🇲🇳 MNG (+976)",
        "phone_format_with_name": "🇲🇳 Mongolia (+976)"
    },
    {
        "iso2": "ME",
        "iso3": "MNE",
        "name": "Montenegro",
        "phone_code": "+382",
        "emoji": "🇲🇪",
        "phone_format_with_iso": "🇲🇪 MNE (+382)",
        "phone_format_with_name": "🇲🇪 Montenegro (+382)"
    },

    {
        "iso2": "MA",
        "iso3": "MAR",
        "name": "Morocco",
        "phone_code": "+212",
        "emoji": "🇲🇦",
        "phone_format_with_iso": "🇲🇦 MAR (+212)",
        "phone_format_with_name": "🇲🇦 Morocco (+212)"
    },
    {
        "iso2": "MZ",
        "iso3": "MOZ",
        "name": "Mozambique",
        "phone_code": "+258",
        "emoji": "🇲🇿",
        "phone_format_with_iso": "🇲🇿 MOZ (+258)",
        "phone_format_with_name": "🇲🇿 Mozambique (+258)"
    },
    {
        "iso2": "MM",
        "iso3": "MMR",
        "name": "Myanmar",
        "phone_code": "+95",
        "emoji": "🇲🇲",
        "phone_format_with_iso": "🇲🇲 MMR (+95)",
        "phone_format_with_name": "🇲🇲 Myanmar (+95)"
    },
    {
        "iso2": "NA",
        "iso3": "NAM",
        "name": "Namibia",
        "phone_code": "+264",
        "emoji": "🇳🇦",
        "phone_format_with_iso": "🇳🇦 NAM (+264)",
        "phone_format_with_name": "🇳🇦 Namibia (+264)"
    },
    {
        "iso2": "NR",
        "iso3": "NRU",
        "name": "Nauru",
        "phone_code": "+674",
        "emoji": "🇳🇷",
        "phone_format_with_iso": "🇳🇷 NRU (+674)",
        "phone_format_with_name": "🇳🇷 Nauru (+674)"
    },
    {
        "iso2": "NP",
        "iso3": "NPL",
        "name": "Nepal",
        "phone_code": "+977",
        "emoji": "🇳🇵",
        "phone_format_with_iso": "🇳🇵 NPL (+977)",
        "phone_format_with_name": "🇳🇵 Nepal (+977)"
    },
    {
        "iso2": "BQ",
        "iso3": "BES",
        "name": "Bonaire, Sint Eustatius and Saba",
        "phone_code": "+599",
        "emoji": "🇧🇶",
        "phone_format_with_iso": "🇧🇶 BES (+599)",
        "phone_format_with_name": "🇧🇶 Bonaire, Sint Eustatius and Saba (+599)"
    },
    {
        "iso2": "NL",
        "iso3": "NLD",
        "name": "Netherlands The",
        "phone_code": "+31",
        "emoji": "🇳🇱",
        "phone_format_with_iso": "🇳🇱 NLD (+31)",
        "phone_format_with_name": "🇳🇱 Netherlands The (+31)"
    },
    {
        "iso2": "NC",
        "iso3": "NCL",
        "name": "New Caledonia",
        "phone_code": "+687",
        "emoji": "🇳🇨",
        "phone_format_with_iso": "🇳🇨 NCL (+687)",
        "phone_format_with_name": "🇳🇨 New Caledonia (+687)"
    },
    {
        "iso2": "NZ",
        "iso3": "NZL",
        "name": "New Zealand",
        "phone_code": "+64",
        "emoji": "🇳🇿",
        "phone_format_with_iso": "🇳🇿 NZL (+64)",
        "phone_format_with_name": "🇳🇿 New Zealand (+64)"
    },
    {
        "iso2": "NI",
        "iso3": "NIC",
        "name": "Nicaragua",
        "phone_code": "+505",
        "emoji": "🇳🇮",
        "phone_format_with_iso": "🇳🇮 NIC (+505)",
        "phone_format_with_name": "🇳🇮 Nicaragua (+505)"
    },
    {
        "iso2": "NE",
        "iso3": "NER",
        "name": "Niger",
        "phone_code": "+227",
        "emoji": "🇳🇪",
        "phone_format_with_iso": "🇳🇪 NER (+227)",
        "phone_format_with_name": "🇳🇪 Niger (+227)"
    },
    {
        "iso2": "NG",
        "iso3": "NGA",
        "name": "Nigeria",
        "phone_code": "+234",
        "emoji": "🇳🇬",
        "phone_format_with_iso": "🇳🇬 NGA (+234)",
        "phone_format_with_name": "🇳🇬 Nigeria (+234)"
    },
    {
        "iso2": "NU",
        "iso3": "NIU",
        "name": "Niue",
        "phone_code": "+683",
        "emoji": "🇳🇺",
        "phone_format_with_iso": "🇳🇺 NIU (+683)",
        "phone_format_with_name": "🇳🇺 Niue (+683)"
    },
    {
        "iso2": "NF",
        "iso3": "NFK",
        "name": "Norfolk Island",
        "phone_code": "+672",
        "emoji": "🇳🇫",
        "phone_format_with_iso": "🇳🇫 NFK (+672)",
        "phone_format_with_name": "🇳🇫 Norfolk Island (+672)"
    },

    {
        "iso2": "NO",
        "iso3": "NOR",
        "name": "Norway",
        "phone_code": "+47",
        "emoji": "🇳🇴",
        "phone_format_with_iso": "🇳🇴 NOR (+47)",
        "phone_format_with_name": "🇳🇴 Norway (+47)"
    },
    {
        "iso2": "OM",
        "iso3": "OMN",
        "name": "Oman",
        "phone_code": "+968",
        "emoji": "🇴🇲",
        "phone_format_with_iso": "🇴🇲 OMN (+968)",
        "phone_format_with_name": "🇴🇲 Oman (+968)"
    },

    {
        "iso2": "PW",
        "iso3": "PLW",
        "name": "Palau",
        "phone_code": "+680",
        "emoji": "🇵🇼",
        "phone_format_with_iso": "🇵🇼 PLW (+680)",
        "phone_format_with_name": "🇵🇼 Palau (+680)"
    },
    {
        "iso2": "PS",
        "iso3": "PSE",
        "name": "Palestinian Territory Occupied",
        "phone_code": "+970",
        "emoji": "🇵🇸",
        "phone_format_with_iso": "🇵🇸 PSE (+970)",
        "phone_format_with_name": "🇵🇸 Palestinian Territory Occupied (+970)"
    },
    {
        "iso2": "PA",
        "iso3": "PAN",
        "name": "Panama",
        "phone_code": "+507",
        "emoji": "🇵🇦",
        "phone_format_with_iso": "🇵🇦 PAN (+507)",
        "phone_format_with_name": "🇵🇦 Panama (+507)"
    },
    {
        "iso2": "PG",
        "iso3": "PNG",
        "name": "Papua new Guinea",
        "phone_code": "+675",
        "emoji": "🇵🇬",
        "phone_format_with_iso": "🇵🇬 PNG (+675)",
        "phone_format_with_name": "🇵🇬 Papua new Guinea (+675)"
    },
    {
        "iso2": "PY",
        "iso3": "PRY",
        "name": "Paraguay",
        "phone_code": "+595",
        "emoji": "🇵🇾",
        "phone_format_with_iso": "🇵🇾 PRY (+595)",
        "phone_format_with_name": "🇵🇾 Paraguay (+595)"
    },
    {
        "iso2": "PE",
        "iso3": "PER",
        "name": "Peru",
        "phone_code": "+51",
        "emoji": "🇵🇪",
        "phone_format_with_iso": "🇵🇪 PER (+51)",
        "phone_format_with_name": "🇵🇪 Peru (+51)"
    },
    {
        "iso2": "PH",
        "iso3": "PHL",
        "name": "Philippines",
        "phone_code": "+63",
        "emoji": "🇵🇭",
        "phone_format_with_iso": "🇵🇭 PHL (+63)",
        "phone_format_with_name": "🇵🇭 Philippines (+63)"
    },
    {
        "iso2": "PN",
        "iso3": "PCN",
        "name": "Pitcairn Island",
        "phone_code": "+870",
        "emoji": "🇵🇳",
        "phone_format_with_iso": "🇵🇳 PCN (+870)",
        "phone_format_with_name": "🇵🇳 Pitcairn Island (+870)"
    },
    {
        "iso2": "PL",
        "iso3": "POL",
        "name": "Poland",
        "phone_code": "+48",
        "emoji": "🇵🇱",
        "phone_format_with_iso": "🇵🇱 POL (+48)",
        "phone_format_with_name": "🇵🇱 Poland (+48)"
    },
    {
        "iso2": "PT",
        "iso3": "PRT",
        "name": "Portugal",
        "phone_code": "+351",
        "emoji": "🇵🇹",
        "phone_format_with_iso": "🇵🇹 PRT (+351)",
        "phone_format_with_name": "🇵🇹 Portugal (+351)"
    },

    {
        "iso2": "QA",
        "iso3": "QAT",
        "name": "Qatar",
        "phone_code": "+974",
        "emoji": "🇶🇦",
        "phone_format_with_iso": "🇶🇦 QAT (+974)",
        "phone_format_with_name": "🇶🇦 Qatar (+974)"
    },
    {
        "iso2": "RE",
        "iso3": "REU",
        "name": "Reunion",
        "phone_code": "+262",
        "emoji": "🇷🇪",
        "phone_format_with_iso": "🇷🇪 REU (+262)",
        "phone_format_with_name": "🇷🇪 Reunion (+262)"
    },
    {
        "iso2": "RO",
        "iso3": "ROU",
        "name": "Romania",
        "phone_code": "+40",
        "emoji": "🇷🇴",
        "phone_format_with_iso": "🇷🇴 ROU (+40)",
        "phone_format_with_name": "🇷🇴 Romania (+40)"
    },
    {
        "iso2": "RU",
        "iso3": "RUS",
        "name": "Russia",
        "phone_code": "+7",
        "emoji": "🇷🇺",
        "phone_format_with_iso": "🇷🇺 RUS (+7)",
        "phone_format_with_name": "🇷🇺 Russia (+7)"
    },
    {
        "iso2": "RW",
        "iso3": "RWA",
        "name": "Rwanda",
        "phone_code": "+250",
        "emoji": "🇷🇼",
        "phone_format_with_iso": "🇷🇼 RWA (+250)",
        "phone_format_with_name": "🇷🇼 Rwanda (+250)"
    },
    {
        "iso2": "SH",
        "iso3": "SHN",
        "name": "Saint Helena",
        "phone_code": "+290",
        "emoji": "🇸🇭",
        "phone_format_with_iso": "🇸🇭 SHN (+290)",
        "phone_format_with_name": "🇸🇭 Saint Helena (+290)"
    },


    {
        "iso2": "PM",
        "iso3": "SPM",
        "name": "Saint Pierre and Miquelon",
        "phone_code": "+508",
        "emoji": "🇵🇲",
        "phone_format_with_iso": "🇵🇲 SPM (+508)",
        "phone_format_with_name": "🇵🇲 Saint Pierre and Miquelon (+508)"
    },

    {
        "iso2": "BL",
        "iso3": "BLM",
        "name": "Saint-Barthelemy",
        "phone_code": "+590",
        "emoji": "🇧🇱",
        "phone_format_with_iso": "🇧🇱 BLM (+590)",
        "phone_format_with_name": "🇧🇱 Saint-Barthelemy (+590)"
    },
    {
        "iso2": "MF",
        "iso3": "MAF",
        "name": "Saint-Martin (French part)",
        "phone_code": "+590",
        "emoji": "🇲🇫",
        "phone_format_with_iso": "🇲🇫 MAF (+590)",
        "phone_format_with_name": "🇲🇫 Saint-Martin (French part) (+590)"
    },
    {
        "iso2": "WS",
        "iso3": "WSM",
        "name": "Samoa",
        "phone_code": "+685",
        "emoji": "🇼🇸",
        "phone_format_with_iso": "🇼🇸 WSM (+685)",
        "phone_format_with_name": "🇼🇸 Samoa (+685)"
    },
    {
        "iso2": "SM",
        "iso3": "SMR",
        "name": "San Marino",
        "phone_code": "+378",
        "emoji": "🇸🇲",
        "phone_format_with_iso": "🇸🇲 SMR (+378)",
        "phone_format_with_name": "🇸🇲 San Marino (+378)"
    },
    {
        "iso2": "ST",
        "iso3": "STP",
        "name": "Sao Tome and Principe",
        "phone_code": "+239",
        "emoji": "🇸🇹",
        "phone_format_with_iso": "🇸🇹 STP (+239)",
        "phone_format_with_name": "🇸🇹 Sao Tome and Principe (+239)"
    },
    {
        "iso2": "SA",
        "iso3": "SAU",
        "name": "Saudi Arabia",
        "phone_code": "+966",
        "emoji": "🇸🇦",
        "phone_format_with_iso": "🇸🇦 SAU (+966)",
        "phone_format_with_name": "🇸🇦 Saudi Arabia (+966)"
    },
    {
        "iso2": "SN",
        "iso3": "SEN",
        "name": "Senegal",
        "phone_code": "+221",
        "emoji": "🇸🇳",
        "phone_format_with_iso": "🇸🇳 SEN (+221)",
        "phone_format_with_name": "🇸🇳 Senegal (+221)"
    },
    {
        "iso2": "RS",
        "iso3": "SRB",
        "name": "Serbia",
        "phone_code": "+381",
        "emoji": "🇷🇸",
        "phone_format_with_iso": "🇷🇸 SRB (+381)",
        "phone_format_with_name": "🇷🇸 Serbia (+381)"
    },
    {
        "iso2": "SC",
        "iso3": "SYC",
        "name": "Seychelles",
        "phone_code": "+248",
        "emoji": "🇸🇨",
        "phone_format_with_iso": "🇸🇨 SYC (+248)",
        "phone_format_with_name": "🇸🇨 Seychelles (+248)"
    },
    {
        "iso2": "SL",
        "iso3": "SLE",
        "name": "Sierra Leone",
        "phone_code": "+232",
        "emoji": "🇸🇱",
        "phone_format_with_iso": "🇸🇱 SLE (+232)",
        "phone_format_with_name": "🇸🇱 Sierra Leone (+232)"
    },
    {
        "iso2": "SG",
        "iso3": "SGP",
        "name": "Singapore",
        "phone_code": "+65",
        "emoji": "🇸🇬",
        "phone_format_with_iso": "🇸🇬 SGP (+65)",
        "phone_format_with_name": "🇸🇬 Singapore (+65)"
    },
    {
        "iso2": "SK",
        "iso3": "SVK",
        "name": "Slovakia",
        "phone_code": "+421",
        "emoji": "🇸🇰",
        "phone_format_with_iso": "🇸🇰 SVK (+421)",
        "phone_format_with_name": "🇸🇰 Slovakia (+421)"
    },
    {
        "iso2": "SI",
        "iso3": "SVN",
        "name": "Slovenia",
        "phone_code": "+386",
        "emoji": "🇸🇮",
        "phone_format_with_iso": "🇸🇮 SVN (+386)",
        "phone_format_with_name": "🇸🇮 Slovenia (+386)"
    },
    {
        "iso2": "SB",
        "iso3": "SLB",
        "name": "Solomon Islands",
        "phone_code": "+677",
        "emoji": "🇸🇧",
        "phone_format_with_iso": "🇸🇧 SLB (+677)",
        "phone_format_with_name": "🇸🇧 Solomon Islands (+677)"
    },
    {
        "iso2": "SO",
        "iso3": "SOM",
        "name": "Somalia",
        "phone_code": "+252",
        "emoji": "🇸🇴",
        "phone_format_with_iso": "🇸🇴 SOM (+252)",
        "phone_format_with_name": "🇸🇴 Somalia (+252)"
    },
    {
        "iso2": "ZA",
        "iso3": "ZAF",
        "name": "South Africa",
        "phone_code": "+27",
        "emoji": "🇿🇦",
        "phone_format_with_iso": "🇿🇦 ZAF (+27)",
        "phone_format_with_name": "🇿🇦 South Africa (+27)"
    },
    {
        "iso2": "GS",
        "iso3": "SGS",
        "name": "South Georgia",
        "phone_code": "+",
        "emoji": "🇬🇸",
        "phone_format_with_iso": "🇬🇸 SGS (+)",
        "phone_format_with_name": "🇬🇸 South Georgia (+)"
    },
    {
        "iso2": "SS",
        "iso3": "SSD",
        "name": "South Sudan",
        "phone_code": "+211",
        "emoji": "🇸🇸",
        "phone_format_with_iso": "🇸🇸 SSD (+211)",
        "phone_format_with_name": "🇸🇸 South Sudan (+211)"
    },

    {
        "iso2": "LK",
        "iso3": "LKA",
        "name": "Sri Lanka",
        "phone_code": "+94",
        "emoji": "🇱🇰",
        "phone_format_with_iso": "🇱🇰 LKA (+94)",
        "phone_format_with_name": "🇱🇰 Sri Lanka (+94)"
    },
    {
        "iso2": "SD",
        "iso3": "SDN",
        "name": "Sudan",
        "phone_code": "+249",
        "emoji": "🇸🇩",
        "phone_format_with_iso": "🇸🇩 SDN (+249)",
        "phone_format_with_name": "🇸🇩 Sudan (+249)"
    },
    {
        "iso2": "SR",
        "iso3": "SUR",
        "name": "Suriname",
        "phone_code": "+597",
        "emoji": "🇸🇷",
        "phone_format_with_iso": "🇸🇷 SUR (+597)",
        "phone_format_with_name": "🇸🇷 Suriname (+597)"
    },
    {
        "iso2": "SJ",
        "iso3": "SJM",
        "name": "Svalbard And Jan Mayen Islands",
        "phone_code": "+47",
        "emoji": "🇸🇯",
        "phone_format_with_iso": "🇸🇯 SJM (+47)",
        "phone_format_with_name": "🇸🇯 Svalbard And Jan Mayen Islands (+47)"
    },
    {
        "iso2": "SZ",
        "iso3": "SWZ",
        "name": "Swaziland",
        "phone_code": "+268",
        "emoji": "🇸🇿",
        "phone_format_with_iso": "🇸🇿 SWZ (+268)",
        "phone_format_with_name": "🇸🇿 Swaziland (+268)"
    },
    {
        "iso2": "SE",
        "iso3": "SWE",
        "name": "Sweden",
        "phone_code": "+46",
        "emoji": "🇸🇪",
        "phone_format_with_iso": "🇸🇪 SWE (+46)",
        "phone_format_with_name": "🇸🇪 Sweden (+46)"
    },
    {
        "iso2": "CH",
        "iso3": "CHE",
        "name": "Switzerland",
        "phone_code": "+41",
        "emoji": "🇨🇭",
        "phone_format_with_iso": "🇨🇭 CHE (+41)",
        "phone_format_with_name": "🇨🇭 Switzerland (+41)"
    },
    {
        "iso2": "SY",
        "iso3": "SYR",
        "name": "Syria",
        "phone_code": "+963",
        "emoji": "🇸🇾",
        "phone_format_with_iso": "🇸🇾 SYR (+963)",
        "phone_format_with_name": "🇸🇾 Syria (+963)"
    },
    {
        "iso2": "TW",
        "iso3": "TWN",
        "name": "Taiwan",
        "phone_code": "+886",
        "emoji": "🇹🇼",
        "phone_format_with_iso": "🇹🇼 TWN (+886)",
        "phone_format_with_name": "🇹🇼 Taiwan (+886)"
    },
    {
        "iso2": "TJ",
        "iso3": "TJK",
        "name": "Tajikistan",
        "phone_code": "+992",
        "emoji": "🇹🇯",
        "phone_format_with_iso": "🇹🇯 TJK (+992)",
        "phone_format_with_name": "🇹🇯 Tajikistan (+992)"
    },
    {
        "iso2": "TZ",
        "iso3": "TZA",
        "name": "Tanzania",
        "phone_code": "+255",
        "emoji": "🇹🇿",
        "phone_format_with_iso": "🇹🇿 TZA (+255)",
        "phone_format_with_name": "🇹🇿 Tanzania (+255)"
    },
    {
        "iso2": "TH",
        "iso3": "THA",
        "name": "Thailand",
        "phone_code": "+66",
        "emoji": "🇹🇭",
        "phone_format_with_iso": "🇹🇭 THA (+66)",
        "phone_format_with_name": "🇹🇭 Thailand (+66)"
    },
    {
        "iso2": "TG",
        "iso3": "TGO",
        "name": "Togo",
        "phone_code": "+228",
        "emoji": "🇹🇬",
        "phone_format_with_iso": "🇹🇬 TGO (+228)",
        "phone_format_with_name": "🇹🇬 Togo (+228)"
    },
    {
        "iso2": "TK",
        "iso3": "TKL",
        "name": "Tokelau",
        "phone_code": "+690",
        "emoji": "🇹🇰",
        "phone_format_with_iso": "🇹🇰 TKL (+690)",
        "phone_format_with_name": "🇹🇰 Tokelau (+690)"
    },
    {
        "iso2": "TO",
        "iso3": "TON",
        "name": "Tonga",
        "phone_code": "+676",
        "emoji": "🇹🇴",
        "phone_format_with_iso": "🇹🇴 TON (+676)",
        "phone_format_with_name": "🇹🇴 Tonga (+676)"
    },

    {
        "iso2": "TN",
        "iso3": "TUN",
        "name": "Tunisia",
        "phone_code": "+216",
        "emoji": "🇹🇳",
        "phone_format_with_iso": "🇹🇳 TUN (+216)",
        "phone_format_with_name": "🇹🇳 Tunisia (+216)"
    },
    {
        "iso2": "TR",
        "iso3": "TUR",
        "name": "Turkey",
        "phone_code": "+90",
        "emoji": "🇹🇷",
        "phone_format_with_iso": "🇹🇷 TUR (+90)",
        "phone_format_with_name": "🇹🇷 Turkey (+90)"
    },
    {
        "iso2": "TM",
        "iso3": "TKM",
        "name": "Turkmenistan",
        "phone_code": "+993",
        "emoji": "🇹🇲",
        "phone_format_with_iso": "🇹🇲 TKM (+993)",
        "phone_format_with_name": "🇹🇲 Turkmenistan (+993)"
    },

    {
        "iso2": "TV",
        "iso3": "TUV",
        "name": "Tuvalu",
        "phone_code": "+688",
        "emoji": "🇹🇻",
        "phone_format_with_iso": "🇹🇻 TUV (+688)",
        "phone_format_with_name": "🇹🇻 Tuvalu (+688)"
    },
    {
        "iso2": "UG",
        "iso3": "UGA",
        "name": "Uganda",
        "phone_code": "+256",
        "emoji": "🇺🇬",
        "phone_format_with_iso": "🇺🇬 UGA (+256)",
        "phone_format_with_name": "🇺🇬 Uganda (+256)"
    },
    {
        "iso2": "UA",
        "iso3": "UKR",
        "name": "Ukraine",
        "phone_code": "+380",
        "emoji": "🇺🇦",
        "phone_format_with_iso": "🇺🇦 UKR (+380)",
        "phone_format_with_name": "🇺🇦 Ukraine (+380)"
    },
    {
        "iso2": "AE",
        "iso3": "ARE",
        "name": "United Arab Emirates",
        "phone_code": "+971",
        "emoji": "🇦🇪",
        "phone_format_with_iso": "🇦🇪 ARE (+971)",
        "phone_format_with_name": "🇦🇪 United Arab Emirates (+971)"
    },

    {
        "iso2": "UM",
        "iso3": "UMI",
        "name": "United States Minor Outlying Islands",
        "phone_code": "+1",
        "emoji": "🇺🇲",
        "phone_format_with_iso": "🇺🇲 UMI (+1)",
        "phone_format_with_name": "🇺🇲 United States Minor Outlying Islands (+1)"
    },
    {
        "iso2": "UY",
        "iso3": "URY",
        "name": "Uruguay",
        "phone_code": "+598",
        "emoji": "🇺🇾",
        "phone_format_with_iso": "🇺🇾 URY (+598)",
        "phone_format_with_name": "🇺🇾 Uruguay (+598)"
    },
    {
        "iso2": "UZ",
        "iso3": "UZB",
        "name": "Uzbekistan",
        "phone_code": "+998",
        "emoji": "🇺🇿",
        "phone_format_with_iso": "🇺🇿 UZB (+998)",
        "phone_format_with_name": "🇺🇿 Uzbekistan (+998)"
    },
    {
        "iso2": "VU",
        "iso3": "VUT",
        "name": "Vanuatu",
        "phone_code": "+678",
        "emoji": "🇻🇺",
        "phone_format_with_iso": "🇻🇺 VUT (+678)",
        "phone_format_with_name": "🇻🇺 Vanuatu (+678)"
    },
    {
        "iso2": "VA",
        "iso3": "VAT",
        "name": "Vatican City State (Holy See)",
        "phone_code": "+379",
        "emoji": "🇻🇦",
        "phone_format_with_iso": "🇻🇦 VAT (+379)",
        "phone_format_with_name": "🇻🇦 Vatican City State (Holy See) (+379)"
    },
    {
        "iso2": "VE",
        "iso3": "VEN",
        "name": "Venezuela",
        "phone_code": "+58",
        "emoji": "🇻🇪",
        "phone_format_with_iso": "🇻🇪 VEN (+58)",
        "phone_format_with_name": "🇻🇪 Venezuela (+58)"
    },
    {
        "iso2": "VN",
        "iso3": "VNM",
        "name": "Vietnam",
        "phone_code": "+84",
        "emoji": "🇻🇳",
        "phone_format_with_iso": "🇻🇳 VNM (+84)",
        "phone_format_with_name": "🇻🇳 Vietnam (+84)"
    },

    {
        "iso2": "WF",
        "iso3": "WLF",
        "name": "Wallis And Futuna Islands",
        "phone_code": "+681",
        "emoji": "🇼🇫",
        "phone_format_with_iso": "🇼🇫 WLF (+681)",
        "phone_format_with_name": "🇼🇫 Wallis And Futuna Islands (+681)"
    },
    {
        "iso2": "EH",
        "iso3": "ESH",
        "name": "Western Sahara",
        "phone_code": "+212",
        "emoji": "🇪🇭",
        "phone_format_with_iso": "🇪🇭 ESH (+212)",
        "phone_format_with_name": "🇪🇭 Western Sahara (+212)"
    },
    {
        "iso2": "YE",
        "iso3": "YEM",
        "name": "Yemen",
        "phone_code": "+967",
        "emoji": "🇾🇪",
        "phone_format_with_iso": "🇾🇪 YEM (+967)",
        "phone_format_with_name": "🇾🇪 Yemen (+967)"
    },
    {
        "iso2": "ZM",
        "iso3": "ZMB",
        "name": "Zambia",
        "phone_code": "+260",
        "emoji": "🇿🇲",
        "phone_format_with_iso": "🇿🇲 ZMB (+260)",
        "phone_format_with_name": "🇿🇲 Zambia (+260)"
    },
    {
        "iso2": "ZW",
        "iso3": "ZWE",
        "name": "Zimbabwe",
        "phone_code": "+263",
        "emoji": "🇿🇼",
        "phone_format_with_iso": "🇿🇼 ZWE (+263)",
        "phone_format_with_name": "🇿🇼 Zimbabwe (+263)"
    },
    {
        "iso2": "XK",
        "iso3": "XKX",
        "name": "Kosovo",
        "phone_code": "+383",
        "emoji": "🇽🇰",
        "phone_format_with_iso": "🇽🇰 XKX (+383)",
        "phone_format_with_name": "🇽🇰 Kosovo (+383)"
    },
    {
        "iso2": "CW",
        "iso3": "CUW",
        "name": "Curaçao",
        "phone_code": "+599",
        "emoji": "🇨🇼",
        "phone_format_with_iso": "🇨🇼 CUW (+599)",
        "phone_format_with_name": "🇨🇼 Curaçao (+599)"
    },

];




